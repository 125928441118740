<template>
  <ion-page>
    <ion-content fullscreen>
      <ion-card color="success">
        <ion-card-content v-if="orderInfo">
          <p>{{$t('labelChipset')}}: </p>
          
          <template v-for="(item, index) in categoryList" :key="index">
            <p v-if="item.title">{{item.title}}:
              <ion-text>{{ item.data }}</ion-text>
            </p>
          </template>

          <ion-text>
            <h1 v-if="orderInfo.grade_num">{{$t('labelGrade')}} : {{ grade_name.cate_name }} : {{ orderInfo.grade_price }}</h1>
            <h2 v-if="orderInfo.grade_memo">{{$t('labelGradeMemo')}} : {{ orderInfo.grade_memo }}</h2>
          </ion-text>
          
          <ion-text>
            <h1 v-if="orderInfo.special">{{$t('labelSpecial')}} : {{ orderInfo.special }}</h1>
            <h2 v-if="orderInfo.special_memo">{{$t('labelSpecialMemo')}} : {{ orderInfo.special_memo }}</h2>
          </ion-text>

          <ion-text>
            <h1>{{$t('labelTotalPrice')}} : {{ total_price }}</h1>
          </ion-text>
        </ion-card-content>
      </ion-card>

      <template v-if="orderInfo.is_confirm === 'F' || orderInfo.is_confirm === 'C'">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="12">
              <ion-item>
                <ion-label>{{$t('labelEmployId')}} : {{ orderInfo.savina_shop_code }}</ion-label>
              </ion-item>
              <ion-item>
                <ion-label>{{$t('labelOrderName')}} : {{ orderInfo.order_name }}</ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="12">
              <template v-if="orderInfo.order_picture == '' || orderInfo.order_picture === null">
                <ion-img
                  src="/assets/images/common/no-image.png"                
                />
              </template>
              <template v-else>
                <ion-img
                  :src="orderInfo.order_picture"
                  @ionError="order_picture = '/assets/images/common/no-image.png'"
                />
              </template>

            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid>
          <ion-row>
            <ion-col><ion-button color="medium" size="" expand="full" @click="handleBack">{{$t('buttonLabelBack')}}</ion-button></ion-col>
          </ion-row>
        </ion-grid>
      </template>
      <template v-else>
        <ion-item>
          <ion-label position="floating">{{$t('labelEmployId')}}</ion-label>
          <ion-input :value="savina_shop_code" :placeholder="$t('labelEmployId')" @ionInput="handleInput('savina_shop_code', $event)"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">{{$t('labelOrderName')}}</ion-label>
          <ion-input :value="order_name" :placeholder="$t('labelOrderName')" @ionInput="handleInput('order_name', $event)"></ion-input>
        </ion-item>

        <ion-grid>
          <ion-row class="l-photo">
            <ion-col size="12">
              <ion-img
                v-if="order_picture"
                :src="order_picture"
                @ionError="order_picture = '/assets/images/common/no-image.png'"
                @click="handleOrderPicture()"
              />
              <ion-button v-if="!order_picture" @click="handleOrderPicture()">
                <span>{{$t('labelOrderPicture')}}</span>
                <ion-icon :icon="cameraOutline"></ion-icon>
              </ion-button>
              <ion-button v-if="order_picture" class="clear" @click="removeOrderPicture()">
                <ion-icon :icon="trash"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid>
          <ion-row>
            <ion-col size="12" size-md="6"><ion-button color="medium" size="" expand="full" @click="handleCanceledSale">{{$t('buttonLabelCanceledSale')}}</ion-button></ion-col>
            <ion-col size="12" size-md="6"><ion-button color="success" size="" expand="full" @click="handleApplySale">{{$t('buttonLabelApplySale')}}</ion-button></ion-col>
          </ion-row>
        </ion-grid>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage,
  IonContent, 
  IonButton, 
  IonCard, 
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  toastController,
  loadingController,
  // useIonRouter
} from '@ionic/vue'
import { cameraOutline, trash } from 'ionicons/icons'
import { Camera, CameraResultType } from '@capacitor/camera'
import { onMounted, ref } from 'vue'
import { useRoute/* , useRouter */ } from 'vue-router'
import { useI18n } from 'vue-i18n';
import { addFileData, listData, updateData } from '@/api/board/common'
import { DataURIToBlob } from '@/utils/commonUtils'

export default {
  name: 'apply-detail',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonCard, 
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' });
    const route = useRoute()
    // const router = useIonRouter()
    const total_price = ref(0)
    const grade_name = ref(undefined)
    const orderInfo = ref({})
    const qrcodeInfo = ref({})
    let orderNum = ref(undefined)
    let order_picture = ref(undefined)
    let savina_shop_code = ref(undefined)
    let order_name = ref(undefined)

    
    //카테고리 정보 호출
    const mainCategory = ref({})
    const subCategory = ref([])
    //화면출려
    const categoryList = ref([])

    onMounted(async () => {
      await getCategoryAll() //정보 호출
      await getOrderNum()
      await getDetailData()
    })

        
    //카테고리 호출
    const getCategoryAll = async () => {
      const apiUrl = '/api/appliances/allcategory'
      const response = await listData(apiUrl)

      response.forEach(item => {
        if(item.p_cate_key == '0' && item.is_del == 'N' && item.cate_num != '5' ){      
          mainCategory.value[item.cate_num] = item
        }else if(item.is_del == 'N'){
          subCategory.value.push(item)
        }
      });
    }

    const updateAppliancesOrderIsConfirm = async (is_confirm) => {
      const apiUrl = `/api/appliances/order/`
      const payload = {
        num: Number(route.params.num),
        is_confirm: is_confirm
      }
      await updateData(apiUrl, payload).then(() => {
        if (is_confirm === 'F') {
          location.href = '/appliances/checkup/apply'
        } else if (is_confirm === 'C') {
          location.href = '/appliances/checkup/apply'
        }
      })
    }

    const getDetailData = async () => {
      let apiUrl = `/api/appliances/order/orderdetail/${Number(route.params.num)}`
      await listData(apiUrl).then(response => {
        orderInfo.value = response
        qrcodeInfo.value = ''
        total_price.value = response.total_price
        //grade_name.value = response.grade_name

        if(response.item_1_num != ''){
          categoryList.value.push({
            title : t('labelCategory'),
            data : mainCategory.value[response.item_1_num].cate_name,
          })
        }

        let itemList = []
        itemList.push(response.item_2_num)
        itemList.push(response.item_3_num)
        itemList.push(response.item_4_num)
        itemList.push(response.item_5_num)
        itemList.push(response.item_6_num)
        itemList.push(response.item_7_num)
        itemList.push(response.item_8_num)
        itemList.push(response.item_9_num)
        itemList.push(response.item_10_num)
        itemList.push(response.item_11_num)
        itemList.push(response.grade_num)

        //null 제거
        itemList = itemList.filter((value) => {
          if(value !== null && itemList != ''){
            return value
          }
        })

        itemList.forEach(value => {
            //값 호출
            const subCategorySelect = subCategory.value.filter((value2) => {
              if(value2.cate_num == value){
                return value2
              }
            })
            //타이틀명 가져오기
            if(subCategorySelect.length > 0){              
                const subCategoryTitleSelect = subCategory.value.filter((value3) => { //eslint-disable-line no-unused-vars
                if(value3.cate_num == subCategorySelect[0].p_cate_key){
                  return value3
                }
              })
              
              //전역변수에 넣어주기
              if(subCategoryTitleSelect.length > 0){
                categoryList.value.push({
                   title : subCategoryTitleSelect[0].cate_name,
                  data : subCategorySelect[0].cate_name,
                 })
              }              
            }

            //등급
            grade_name.value = subCategory.value.find((v) => {
              return v.cate_num == value
            })
        });


        console.log(categoryList.value)
      })
    }


    const getOrderNum = async () => {
      const apiUrl = `/api/appliances/order/ordernum/${route.params.num}`
      orderNum.value = await listData(apiUrl)
    }

    const handleBack = async () => {
      location.href = "/appliances/checkup/result"
      // router.back()
    }

    const handleCanceledSale = async () => {
      await updateAppliancesOrderIsConfirm('C')
    }
    
    const handleApplySale = () => {
      if (checkValidate()) {
        postOrderPicture()
      }
    }

    const postOrderPicture = async () => {
      showLoading(true)
      const apiUrl = '/api/order/picture'
      let formData = new FormData()
      const file = DataURIToBlob(order_picture)
      const fileOfBlob = new File([file], 'appliances_order_picture.jpg', {type:'image/jpeg'})
      formData.append('file', fileOfBlob)
      formData.append('ord_number', orderNum.value)
      await addFileData(apiUrl, formData).then(response => {
        if(response.result === 'success') {
          putOrderInfo()
        }
      })
      await updateAppliancesOrderIsConfirm('F')
      showLoading(false)
    }

    const putOrderInfo = async () => {
      const apiUrl = `/api/appliances/order/`
      const param = {
        num: Number(route.params.num),
        savina_shop_code: savina_shop_code.value,
        order_name: order_name.value,            
        order_picture: `https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/hardware/${orderNum.value}/appliances_order_picture.jpg`
      }
      await updateData(apiUrl, param).then(() => {
        location.href = '/appliances/checkup/apply'
      })
    } 

    const handleInput = (id, e) => {
      if (id === 'savina_shop_code') {
        savina_shop_code.value = e.target.value
      } else if (id === 'order_name') {
        order_name.value = e.target.value
      }
    }

    const handleOrderPicture = async () => {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      order_picture.value = image.dataUrl
    }

    const removeOrderPicture = async () => {
      order_picture.value = undefined
    }

    const checkValidate = () => {
      let message;
      if (!savina_shop_code.value) {
        message = t('messageSavinaShopCodeText')
        showMessage(message)
        return false
      } else if (!order_name.value) {
        message = t('messageOrderNameText')
        showMessage(message)
        return false
      } else if (!order_picture.value) {
        message = t('messageOrderPictureText')
        showMessage(message)
        return false
      } else {
        return true
      }
    }
    
    const showMessage = (message) => {
      toastController.create({
        message: message,
        duration: 3000,
        color: 'danger',
        cssClass: 'custom-toast',
        buttons: [
          {
            text: t('buttonLabelClose'),
            role: 'cancel'
          }
        ],
      }).then(toast => toast.present())
    }
    
    const showLoading = async (type) => {
      const loading = await loadingController.create({
        message: t('messageLoadingText'),
        // duration: 3000,
        cssClass: 'custom-loading'
      })
      if (type) {
        loading.present()
      } else {
        loading.dismiss()
      }
    }

    return {
      categoryList,
      cameraOutline,
      trash,
      savina_shop_code,
      order_name,
      order_picture,
      orderInfo,
      qrcodeInfo,
      total_price,
      grade_name,
      putOrderInfo,
      handleBack,
      handleCanceledSale,
      handleApplySale,
      handleInput,
      handleOrderPicture,
      removeOrderPicture
    }
  }
}
</script>

<style scoped>
.l-photo :deep ion-col {
  text-align: center;
  position: relative;
}
.l-photo :deep ion-button,
.l-photo .photo {
  width: 150px;
  height: 150px;
  --background: transparent;
  --background-focused: transparent;
  --background-hover: rgba(0, 0, 0, 0.3);
  border: 2px solid #2a7df6;
  border-radius: 5px;
  font-size: 1em;
  --color: #2a7df6;
}

.l-photo .photo {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.l-photo :deep ion-img {
  position: absolute;
  width: 145px;
  height: 145px;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.l-photo .clear {
  --color: rgb(209, 207, 207);
  font-size: 30px;
}

@media (max-width: 320px) {
  .l-photo {
    flex-direction: column;
  }
  .l-photo :deep ion-col,
  .l-photo .photo {
    width: 100% !important;
    max-width: 100% !important;
    flex: 1 !important;
  }
}
</style>
